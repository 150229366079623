import Swal from "sweetalert2";
import {
    getRequest,
    putRequest,
    createAction,
    stopLoading,
    startLoading,
    authErrorHandler,
    escapeFilterValue
} from "openstack-uicore-foundation/lib/utils/actions";

import { Base64 } from 'js-base64';
import {
    getAccessTokenSafely,
    fetchPaginatedData
} from "../utils/utils";
import {
  handleAuthAlert,
  withAlertHandler,
  alertNetworkOrServerErrorAndRethrow
} from "../utils/errorHandling";

export const REQUEST_TICKET            = "REQUEST_TICKET";
export const RECEIVE_TICKET            = "RECEIVE_TICKET";
export const REQUEST_TICKETS           = "REQUEST_TICKETS";
export const RECEIVE_TICKETS           = "RECEIVE_TICKETS";
export const SET_SELECTED_TICKET       = "SET_SELECTED_TICKET";
export const CLEAR_SELECTED_TICKET     = "CLEAR_SELECTED_TICKET";
export const TICKET_UPDATED            = "TICKET_UPDATED";

export const findTicketByQRCode = (qrCode) => async (dispatch, getState) => {
    const { baseState: { accessTokenQS, summit } } = getState();
    const accessToken = await getAccessTokenSafely(accessTokenQS);

    const params = {
        access_token : accessToken,
        "filter[]"   : [`is_printable==1`],
        expand       : "owner,owner.extra_questions,ticket_type,badge,badge.type,badge.type.allowed_view_types",
        relations    : "owner.extra_questions"
    };

    let encodedQRCode = Base64.encode(qrCode);

    dispatch(startLoading());
    return getRequest(
        createAction(REQUEST_TICKET),
        createAction(RECEIVE_TICKET),
        `${window.API_BASE_URL}/api/v1/summits/${summit.id}/tickets/${encodedQRCode}`,
        withAlertHandler(authErrorHandler, handleAuthAlert)
    )(params)(dispatch)
        .then((payload) => payload.response)
        .catch(alertNetworkOrServerErrorAndRethrow)
        .finally(() => dispatch(stopLoading()));
};

export const setSelectedTicket = (ticket) => (dispatch) => Promise.resolve().then(() => {
    return dispatch(createAction(SET_SELECTED_TICKET)(ticket));
})

export const clearSelectedTicket = () => (dispatch) => Promise.resolve().then(() => {
    return dispatch(createAction(CLEAR_SELECTED_TICKET)());
})

export const findTicketsByName = (firstName, lastName) => async (dispatch, getState) => {
    const name = `${firstName} ${lastName}`;
    const filters = [`owner_name==${escapeFilterValue(name)}`];
    return getAllTickets({ filters }, { searchTerm: name })(dispatch, getState)
        .catch(alertNetworkOrServerErrorAndRethrow);
};

export const findTicketsByEmail = (email) => async (dispatch, getState) => {
    const filters = [`owner_email==${escapeFilterValue(email)}`];
    return getAllTickets({ filters }, { searchTerm: email })(dispatch, getState)
        .catch(alertNetworkOrServerErrorAndRethrow);
};

export const findExternalTicketsByEmail = (email) => async (dispatch, getState) => {
    const filters = [`owner_email==${escapeFilterValue(email)}`]
    return getAllTickets({ filters }, { searchTerm: email, externalTickets: true })(dispatch, getState)
        .catch(alertNetworkOrServerErrorAndRethrow);
};

export const getAllTickets = (
    { fields, filters, expand, relations, order, perPage },
    config = { externalTickets: false }
) => async (dispatch, getState) => {
    const { baseState: { accessTokenQS, summit, allTickets } } = getState();

    const idFilterMatch = Array.isArray(filters)
        ? filters.forEach(e => e.match(/id==(\d+)/))
        : filters?.match(/id==(\d+)/);

    if (idFilterMatch) {
        const ticketId = idFilterMatch[1];
        const existingTicket = allTickets.find(ticket => ticket.id == ticketId);
        if (existingTicket) return [existingTicket]; // return the ticket as an array for consistency
    }

    const accessToken = await getAccessTokenSafely(accessTokenQS);

    const params = {
        access_token : accessToken,
        fields       : fields ?? "id,number,badge.type.id,badge.type.name",
        expand       : expand ?? "owner,owner.extra_questions,badge,badge.type",
        relations    : relations ?? "none,owner.extra_questions,badge.type.allowed_view_types",
        order        : order ?? "id",
        per_page     : perPage ?? 50
    };

    const { externalTickets, searchTerm } = config;

    const baseFilters = externalTickets ? [] : ["is_printable==1"];
    params["filter[]"] = Array.isArray(filters)
        ? [...filters.filter(e => !e.startsWith("is_printable")), ...baseFilters]
        : [...(filters && !filters.startsWith("is_printable") ? [filters] : []), ...baseFilters];

    let endpoint = `${window.API_BASE_URL}/api/v1/summits/${summit.id}/tickets`;
    if (externalTickets) endpoint += "/external";

    const requestActionPayload = searchTerm ? { searchTerm } : {};

    return fetchPaginatedData(
        REQUEST_TICKETS,
        RECEIVE_TICKETS,
        endpoint,
        withAlertHandler(authErrorHandler, handleAuthAlert),
        requestActionPayload
    )(params)(dispatch);
};

export const saveExtraQuestions = (values) => async (dispatch, getState) => {
    const { baseState: { accessTokenQS, selectedTicket } } = getState();
    const accessToken = await getAccessTokenSafely(accessTokenQS);

    const normalizedEntity = {...values};
    if (!values.attendee_company.id) {
        normalizedEntity["attendee_company"] = values.attendee_company.name;
    } else {
        delete(normalizedEntity["attendee_company"]);
        normalizedEntity["attendee_company_id"] = values.attendee_company.id;
    }

    const params = {
        access_token: accessToken,
        expand: "owner,owner.extra_questions"
    };

    dispatch(startLoading());
    return putRequest(
        null,
        createAction(TICKET_UPDATED),
        `${window.API_BASE_URL}/api/v1/summits/all/orders/all/tickets/${selectedTicket.id}`,
        normalizedEntity,
        authErrorHandler
    )(params)(dispatch).then((payload) => {
        return payload;
    }).catch(e => {
        Swal.fire("Error", "Error saving your questions. Please retry.", "warning");
        return Promise.reject(e);
    }).finally(() => dispatch(stopLoading()));
};
