import {
    getRequest,
    createAction,
    stopLoading,
    startLoading,
    authErrorHandler
} from "openstack-uicore-foundation/lib/utils/actions";
import {
    getAccessTokenSafely,
    fetchPaginatedData
} from "../utils/utils";
import {
  handleAuthAlert,
  withAlertHandler
} from "../utils/errorHandling";
import { getUnixTimestamp, range } from "../utils/methods";

export const REQUEST_SUMMITS            = "REQUEST_SUMMITS";
export const RECEIVE_SUMMITS            = "RECEIVE_SUMMITS";
export const REQUEST_BADGE_FEATURES     = "REQUEST_BADGE_FEATURES";
export const RECEIVE_BADGE_FEATURES     = "RECEIVE_BADGE_FEATURES";
export const REQUEST_SUMMIT             = "REQUEST_SUMMIT";
export const RECEIVE_SUMMIT             = "RECEIVE_SUMMIT";
export const SET_ACCESS_TOKEN_QS        = "SET_ACCESS_TOKEN_QS";
export const GET_EXTRA_QUESTIONS        = "GET_EXTRA_QUESTIONS";
export const RECEIVE_MARKETING_SETTINGS = "RECEIVE_MARKETING_SETTINGS";

export const setAccessTokenQS = (accessToken) => (dispatch) => {
    dispatch(createAction(SET_ACCESS_TOKEN_QS)({accessToken}));
};

export const loadSummits = (includePastSummits = false) => async (dispatch, getState) => {
    const { baseState: { accessTokenQS } } = getState();
    const accessToken = await getAccessTokenSafely(accessTokenQS);

    const filters = [];

    if (!includePastSummits) {
        const today = new Date();
        const currentTimestamp = getUnixTimestamp(today);
        today.setMonth(today.getMonth() - 1);
        const oneMonthAgoTimestamp = getUnixTimestamp(today);

        filters.push(`start_date>=${oneMonthAgoTimestamp}`);
        filters.push(`end_date>=${currentTimestamp}`);
    }

    const params = {
        access_token: accessToken,
        fields: "id,slug,name",
        relations: "none",
        expand: "none",
        "filter[]": filters,
        order: "-id",
        per_page: 10
    };

    const endpoint = `${window.API_BASE_URL}/api/v1/summits/all`;

    return fetchPaginatedData(
        REQUEST_SUMMITS,
        RECEIVE_SUMMITS,
        endpoint,
        authErrorHandler
    )(params)(dispatch);
};

export const getSummit = (summitSlug) => async (dispatch, getState) => {
    const { baseState: { accessTokenQS } } = getState();
    const accessToken = await getAccessTokenSafely(accessTokenQS);

    const params = {
        access_token: accessToken,
        fields: "id,slug,name,logo,registration_disclaimer_content,registration_disclaimer_mandatory",
        relations: "ticket_types,badge_types,badge_features_types,badge_access_level_types,badge_view_types,order_extra_questions",
        expand: "order_extra_questions,order_extra_questions.values,badge_types,badge_view_types,badge_access_level_types,badge_features_types"
    };

    const endpoint = `${window.API_BASE_URL}/api/v1/summits/all/${summitSlug}`;

    dispatch(startLoading());

    return getRequest(
        createAction(REQUEST_SUMMIT),
        createAction(RECEIVE_SUMMIT),
        endpoint,
        authErrorHandler
    )(params)(dispatch)
        .then((payload) => payload.response)
        .finally(() => dispatch(stopLoading()));
};

export const getBadgeFeaturesTypes = (summitId) => async (dispatch, getState) => {
  if (!summitId) throw new Error("Invalid summitId. Unable to fetch badge features.");

  const { baseState: { accessTokenQS } } = getState();
  const accessToken = await getAccessTokenSafely(accessTokenQS);

  const params = {
    access_token: accessToken,
    page: 1,
    per_page: 50
  };

  const endpoint = `${window.API_BASE_URL}/api/v1/summits/${summitId}/badge-feature-types`;

  return fetchPaginatedData(
    REQUEST_BADGE_FEATURES,
    RECEIVE_BADGE_FEATURES,
    endpoint,
    authErrorHandler
  )(params)(dispatch);
};

export const getExtraQuestions = (summit, attendeeId) => async (dispatch, getState) => {
    const { baseState: { accessTokenQS } } = getState();
    const accessToken = await getAccessTokenSafely(accessTokenQS);

    const params = {
        access_token: accessToken,
        expand: "*sub_question_rules,*sub_question,*values",
        order: "order",
        per_page: 5
    };

    const endpoint = `${window.API_BASE_URL}/api/v1/summits/${summit.id}/attendees/${attendeeId}/allowed-extra-questions`;

    return fetchPaginatedData(
        "DUMMY",
        GET_EXTRA_QUESTIONS,
        endpoint,
        authErrorHandler
    )(params)(dispatch);
};

export const getMarketingSettings = (summitId) => async (dispatch) => {
    if (!summitId) throw new Error("Invalid summitId. Unable to fetch marketing settings.");

    const params = {
        per_page: 100
    };

    const endpoint = `${window.MARKETING_API_BASE_URL}/api/public/v1/config-values/all/shows/${summitId}`;

    return fetchPaginatedData(
        "DUMMY",
        RECEIVE_MARKETING_SETTINGS, 
        endpoint,
        withAlertHandler(authErrorHandler, handleAuthAlert)
    )(params)(dispatch);
};
